<template>
  <div class="margins1">
    <h3 class="center">Импорт начислений/выплат</h3>
    <form ref="form" method="post" action="/api/upload/salesin" enctype="multipart/form-data">
      <div>Период {{ dateFormatVarious(date_begin) }} - {{ dateFormatVarious(date_end) }}</div>
      <br />
      <br />

      <PeopleSelector
        label="Получатель данных"
        filter="work"
        @change="id_people = parseInt($event)"
      />
      <br />
      <br />

      <input type="file" name="sales" />
      <br />
      <br />
      <br />
      <input ref="buttonSend" type="button" value="Отправить" @click="fileSend()" />

      <div v-if="output">
        <br />
        <br />
        <div>Источник данных: {{ output.fileNameSrc }}</div>
        <div>Размер: {{ output.fileSize + " " + output.fileSizeUnit }}</div>
        <div>Строк прочитано: {{ output.fileLinesTotal }}</div>
        <div>Статус: {{ output.fileStatus ? "обработан" : "не обработан" }}</div>
        <div v-if="output.fileStatus">Внесено строк: {{ output.fileLinesData }}</div>
        <div v-if="timing">Время обработки: {{ Math.round(timing / 10) / 100 }} сек.</div>
      </div>
    </form>
  </div>
</template>

<script>
import PeopleSelector from "@/components/PeopleSelector.vue";
import { dateFormatJS, dateFormatVarious } from "@/components-js/dateFormat.js";
export default {
  components: { PeopleSelector },

  data() {
    return {
      date_begin: dateFormatJS("2019-11-01"),
      date_end: dateFormatJS(new Date()),
      id_people: null,
      output: null,
      timing: 0,
    };
  },

  methods: {
    followLink(href) {
      this.$router.push(href);
    },

    dateFormatVarious(d) {
      return dateFormatVarious(d);
    },

    async fileSend() {
      this.$refs.buttonSend.disabled = true;
      this.output = null;
      let t1 = new Date().getTime();
      const frmData = new FormData(this.$refs.form);
      frmData.append("id_people", this.id_people);
      frmData.append("date_begin", this.date_begin);
      frmData.append("date_end", this.date_end);

      const resp = await fetch("/api/money/moneymovememtsin", {
        method: "POST",
        body: frmData,
      });
      // this.output = await resp.json();
      resp.json().then(response => {
        this.$refs.buttonSend.disabled = false;
        this.output = response;
        this.timing = new Date().getTime() - t1;
      });
    },
  },
};
</script>
